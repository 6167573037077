import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { FROM_DATE, TO_DATE } from "./constants";
import { isoFormat } from "./dateFormatting";

const useDateRangePickerTrigger = ({
  fromDate,
  toDate,
  flexibleDays,
  showApplyButton,
  onApplyButtonClick,
  onChange,
  onClose,
  onFlexibilityChange,
}) => {
  const [tempFromDate, setTempFromDate] = useState(fromDate);
  const [tempToDate, setTempToDate] = useState(toDate);
  const [tempFlexibleDays, setTempFlexibleDays] = useState(flexibleDays);
  const [defaultMonth, setDefaultMonth] = useState(() =>
    dayjs(toDate || fromDate || new Date()),
  );

  const [focusedInput, setFocusedInput] = useState(
    fromDate ? TO_DATE : FROM_DATE,
  );
  const [hoveredFromDate, setHoveredFromDate] = useState(null);
  const [hoveredToDate, setHoveredToDate] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTempFromDate(isoFormat(fromDate));
    setTempToDate(isoFormat(toDate));
    setTempFlexibleDays(flexibleDays);
  }, [fromDate, toDate, flexibleDays]);

  useEffect(() => {
    setFocusedInput(
      (showApplyButton ? tempFromDate : fromDate) ? TO_DATE : FROM_DATE,
    );
  }, [fromDate, showApplyButton, tempFromDate]);

  const onChangeDatesHandler = ({ fromDate, toDate }) => {
    if (showApplyButton) {
      setTempFromDate(isoFormat(fromDate));
      setTempToDate(isoFormat(toDate));
    } else {
      onChange({ fromDate: isoFormat(fromDate), toDate: isoFormat(toDate) });
    }
  };

  const onChangeFlexibleDaysHandler = (flexibleDays) => {
    if (showApplyButton) {
      setTempFlexibleDays(flexibleDays);
    } else {
      onFlexibilityChange(flexibleDays);
    }
  };

  const handleApply = () => {
    onChange({ fromDate: tempFromDate, toDate: tempToDate });
    onFlexibilityChange(tempFlexibleDays);
    onApplyButtonClick();
    setIsVisible(false);
  };

  const onPopoverCloseHandler = () => {
    if (showApplyButton) {
      setTempFromDate(fromDate ? isoFormat(fromDate) : null);
      setTempToDate(toDate ? isoFormat(toDate) : null);
      setTempFlexibleDays(flexibleDays);
    }
    onClose();
    setIsVisible(false);
  };

  const onClearDatesHandler = () => {
    setDefaultMonth(fromDate ? dayjs(fromDate) : dayjs());

    if (showApplyButton) {
      setTempFromDate(null);
      setTempToDate(null);
      setTempFlexibleDays(null);
    } else {
      onChange({ fromDate: null, toDate: null });
      onFlexibilityChange(null);
    }
    setFocusedInput(FROM_DATE);
  };

  const onHoverDatesHandler = ({ hoveredFromDate, hoveredToDate }) => {
    setHoveredFromDate(hoveredFromDate);
    setHoveredToDate(hoveredToDate);
  };

  const onFromDatePreviewClickHandler = () => {
    if (focusedInput !== FROM_DATE) {
      setDefaultMonth(fromDate ? dayjs(fromDate) : dayjs());
      setFocusedInput(FROM_DATE);
      setHoveredToDate(null);
    }
  };

  const onToDatePreviewClickHandler = () => {
    if (
      (showApplyButton ? tempFromDate : fromDate) &&
      focusedInput !== TO_DATE
    ) {
      setDefaultMonth(toDate ? dayjs(toDate) : dayjs());
      setFocusedInput(TO_DATE);
    }
  };

  const getFromDate = ({ parseWithDayjs = false } = {}) => {
    const date = showApplyButton ? tempFromDate : fromDate;
    return parseWithDayjs && date ? dayjs(date) : date;
  };

  const getToDate = ({ parseWithDayjs = false } = {}) => {
    const date = showApplyButton ? tempToDate : toDate;
    return parseWithDayjs && date ? dayjs(date) : date;
  };

  const flexibleDaysValue = showApplyButton ? tempFlexibleDays : flexibleDays;

  return {
    tempFromDate,
    tempToDate,
    flexibleDaysValue,
    focusedInput,
    hoveredFromDate,
    hoveredToDate,
    isVisible,
    setIsVisible,
    getFromDate,
    getToDate,
    handleApply,
    defaultMonth,
    setDefaultMonth,
    onClearDatesHandler,
    onChangeDatesHandler,
    onHoverDatesHandler,
    onPopoverCloseHandler,
    onFromDatePreviewClickHandler,
    onToDatePreviewClickHandler,
    onChangeFlexibleDaysHandler,
  };
};

export default useDateRangePickerTrigger;
