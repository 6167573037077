import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import classNames from "classnames";
import dayjs from "dayjs";
import { useI18n } from "../../../spages/spa/context/I18nContext";
import { Button } from "../../DesignSystem/Button/Button";
import Popover, { placements } from "../../Popover/Popover";
import { FROM_DATE, TO_DATE } from "../constants";
import { formatDatePreviews } from "../dateFormatting";
import DateRangePickerMobile from "../DateRangePickerMobile/DateRangePickerMobile";
import FlexibleDates, {
  FlexibleDatesPreview,
} from "../FlexibleDates/FlexibleDates";
import useDateRangePicker from "../useDateRangePickerTrigger";
import "./DateRangePickerTrigger.scss";

const DateRangePicker = loadable(() => import("../DateRangePicker"), {
  ssr: false,
});

const propTypes = {
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  minBookingDuration: PropTypes.number,
  maxBookingDuration: PropTypes.number,
  minDate: PropTypes.objectOf((propValue) => dayjs.isDayjs(propValue)),
  maxDate: PropTypes.objectOf((propValue) => dayjs.isDayjs(propValue)),
  maxYear: PropTypes.number,
  isDayBlocked: PropTypes.func,
  blockedDurations: PropTypes.arrayOf(
    PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string,
    }),
  ),
  dateRangeSeparator: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  showApplyButton: PropTypes.bool,
  applyButtonText: PropTypes.string,
  onApplyButtonClick: PropTypes.func,
  isMobile: PropTypes.bool,
  isFullDateFormat: PropTypes.bool,
  showFlexibleDates: PropTypes.bool,
  flexibleDays: PropTypes.number,
  onFlexibilityChange: PropTypes.func,
  popoverPlacement: PropTypes.string,
  popoverClassName: PropTypes.string,
  triggerClassName: PropTypes.string,
  previewButtonClassName: PropTypes.string,
  previewButtonActiveClassName: PropTypes.string,
  previewButtonInactiveClassName: PropTypes.string,
  previewButtonSelectedClassName: PropTypes.string,
  previewButtonAppliedClassName: PropTypes.string,
  previewButtonClearedClassName: PropTypes.string,
  dateSeparatorClassName: PropTypes.string,
  dateSeparatorInactiveClassName: PropTypes.string,
  dateCtaPanelClassName: PropTypes.string,
  applyButtonType: PropTypes.string,
  onDatePickerVisible: PropTypes.func,
};

const DateRangePickerTrigger = forwardRef(
  (
    {
      fromDate,
      toDate,
      minBookingDuration = 1,
      maxBookingDuration,
      minDate,
      maxDate,
      maxYear,
      blockedDurations,
      isDayBlocked,
      dateRangeSeparator,
      onChange,
      onClose = () => {},
      showApplyButton = false,
      applyButtonType,
      applyButtonText,
      onApplyButtonClick = () => {},
      isMobile,
      isFullDateFormat,
      showFlexibleDates = false,
      flexibleDays,
      onFlexibilityChange = () => {},
      popoverPlacement,
      popoverClassName,
      triggerClassName,
      previewButtonClassName,
      previewButtonActiveClassName,
      previewButtonInactiveClassName,
      previewButtonSelectedClassName,
      previewButtonAppliedClassName,
      previewButtonClearedClassName,
      dateSeparatorClassName,
      dateSeparatorInactiveClassName,
      dateCtaPanelClassName,
      onDatePickerVisible,
    },
    ref,
  ) => {
    const {
      tempFromDate,
      tempToDate,
      flexibleDaysValue,
      focusedInput,
      hoveredFromDate,
      hoveredToDate,
      isVisible,
      setIsVisible,
      defaultMonth,
      setDefaultMonth,
      getFromDate,
      getToDate,
      handleApply,
      onClearDatesHandler,
      onChangeDatesHandler,
      onHoverDatesHandler,
      onPopoverCloseHandler,
      onFromDatePreviewClickHandler,
      onToDatePreviewClickHandler,
      onChangeFlexibleDaysHandler,
    } = useDateRangePicker({
      fromDate,
      toDate,
      flexibleDays,
      showApplyButton: showApplyButton || isMobile,
      onApplyButtonClick,
      onChange,
      onClose,
      onFlexibilityChange,
    });
    const fromPreviewButtonRef = useRef();
    const { t } = useI18n();

    useImperativeHandle(ref, () => ({
      focus: () => {
        setIsVisible(true);
        fromPreviewButtonRef?.current.focus();
      },
    }));

    useEffect(() => {
      if (onDatePickerVisible) {
        onDatePickerVisible(isVisible);
      }
      // We don't need it to have onDatePickerVisible as a dependency
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);

    const fromDateValue = getFromDate() ?? hoveredFromDate ?? null;
    const toDateValue = getToDate() ?? hoveredToDate ?? null;

    const { fromDatePreview, toDatePreview } = formatDatePreviews({
      fromDate: fromDateValue,
      toDate: toDateValue,
      defaultFromString: t("components.DateRangePicker.moveIn"),
      defaultToString: t("components.DateRangePicker.moveOut"),
      isFullDateFormat,
    });

    const fromDateClassNames = classNames(previewButtonClassName, {
      [previewButtonInactiveClassName]: !isVisible && !fromDate,
      [previewButtonActiveClassName]:
        isVisible && focusedInput === FROM_DATE && (!tempFromDate || !fromDate),
      [previewButtonSelectedClassName]: isVisible && tempFromDate && fromDate,
      [previewButtonAppliedClassName]: !isVisible && fromDate,
      [previewButtonClearedClassName]: isVisible && !tempFromDate,
    });

    const toDateClassNames = classNames(previewButtonClassName, {
      [previewButtonInactiveClassName]: !isVisible && !toDate,
      [previewButtonActiveClassName]:
        (isVisible && focusedInput === TO_DATE && (!tempToDate || !toDate)) ||
        (isVisible && tempFromDate && !tempToDate),
      [previewButtonSelectedClassName]:
        isVisible && focusedInput === TO_DATE && tempToDate,
      [previewButtonAppliedClassName]: !isVisible && tempToDate,
      [previewButtonClearedClassName]: isVisible && !tempToDate,
    });

    const separatorClassName = classNames(dateSeparatorClassName, {
      [dateSeparatorInactiveClassName]: !fromDate,
    });

    const isFooterShown = showApplyButton ? tempFromDate : fromDate;
    const isFlexibilityApplied =
      showFlexibleDates && flexibleDaysValue > 0 && fromDate;

    const handleMonthChange = (newMonthDate) => {
      setDefaultMonth(dayjs(newMonthDate));
    };

    const datePickerDefaultProps = {
      fromDate: getFromDate({ parseWithDayjs: true }),
      toDate: getToDate({ parseWithDayjs: true }),
      onChangeDates: onChangeDatesHandler,
      focusedInput,
      minBookingDuration,
      maxBookingDuration,
      minDate,
      maxDate,
      maxYear,
      isDayBlocked,
      defaultMonth,
      onMonthChange: handleMonthChange,
      blockedDurations,
    };

    return (
      <Popover
        preventAutofocus
        showInDrawer={isMobile}
        drawerHeightPercent={100}
        show={isVisible}
        placement={popoverPlacement || placements.bottomStart}
        usePortal={false}
        hasArrow={false}
        className={classNames(
          "DateRangePickerTrigger-popover",
          popoverClassName,
        )}
        onClose={onPopoverCloseHandler}
        around={
          <div
            onClick={() => setIsVisible(true)}
            className={triggerClassName}
            data-testid="DateRangePickerTrigger"
          >
            <button
              ref={fromPreviewButtonRef}
              onClick={onFromDatePreviewClickHandler}
              className={fromDateClassNames}
              data-testid="DateRangePickerTrigger-fromDate"
              type="button"
            >
              {fromDatePreview}
              {isFlexibilityApplied && (
                <FlexibleDatesPreview value={flexibleDaysValue} />
              )}
            </button>
            <span className={separatorClassName}>{dateRangeSeparator}</span>
            <button
              onClick={onToDatePreviewClickHandler}
              className={toDateClassNames}
              data-testid="DateRangePickerTrigger-toDate"
              type="button"
            >
              {toDatePreview}
              {isFlexibilityApplied && toDateValue && (
                <FlexibleDatesPreview value={flexibleDaysValue} />
              )}
            </button>
          </div>
        }
      >
        <div className="DatePickerContent">
          {isMobile ? (
            <DateRangePickerMobile
              {...datePickerDefaultProps}
              onClearDates={onClearDatesHandler}
            />
          ) : (
            <DateRangePicker
              {...datePickerDefaultProps}
              hoveredFromDate={hoveredFromDate}
              hoveredToDate={hoveredToDate}
              onHoverDates={onHoverDatesHandler}
            />
          )}
          {showFlexibleDates && (
            <FlexibleDates
              selectedFlexibleDays={flexibleDaysValue}
              onChange={onChangeFlexibleDaysHandler}
              disableSelection={showApplyButton ? !tempFromDate : !fromDate}
              isMobile={isMobile}
            />
          )}
          <footer
            className={classNames(
              "DateRangePickerTrigger-footer",
              isFooterShown && dateCtaPanelClassName,
              {
                "DateRangePickerTrigger-footer--mobile": isMobile,
                "DateRangePickerTrigger-footer--mobile-duration":
                  !isFooterShown,
              },
            )}
          >
            {isFooterShown ? (
              <React.Fragment>
                <Button
                  dataTestId="DataRangePickerTrigger-clearDates"
                  className="DateRangePickerTrigger-clearDates"
                  colorVariant={showApplyButton ? "text" : "secondary"}
                  widthVariant="growWithText"
                  size="medium"
                  onClick={onClearDatesHandler}
                >
                  {t("components.DateRangePicker.clearDates")}
                </Button>
                {showApplyButton && (
                  <Button
                    dataTestId="DataRangePickerTrigger-apply"
                    size="large"
                    onClick={handleApply}
                    {...(applyButtonType && { type: applyButtonType })}
                  >
                    {applyButtonText || t("apply")}
                  </Button>
                )}
              </React.Fragment>
            ) : (
              t("components.DateRangePicker.minBookingDuration", {
                minBookingDuration,
              })
            )}
          </footer>
        </div>
      </Popover>
    );
  },
);

DateRangePickerTrigger.propTypes = propTypes;

export default DateRangePickerTrigger;
